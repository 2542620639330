<template>
  <div class="wrap" v-loading="loadingBox" οncοntextmenu="return false;" onselectstart="return false;">


    <div class="goods">
      <div class="goods-params">
        <div>
			
		
          <div class="goods-imgs" >

			    <div  @click="gopreviewUrl(previewUrl)" style="position: absolute;left: 0;top: 0;width: 100%;height: 100%;cursor: pointer;"></div>		  
				  <iframe :src='previewUrl' ></iframe>
  
          </div>
        </div>

        <div class="goods-content">
          <table class="goods-params-tab" border="0" cellpadding="0" cellspacing="0">

            <!-- 价格 -->
            <tr>
              <td style="width: 20%;">{{ $t("goodsDetail.goodsPrice") }}</td>
              <td style="width: 80%;color:red;font-size: 18px;">
                <span>￥{{ priceRes.price }}</span>
              </td>

            </tr>

            <!-- 样品价格 -->
            <tr>
              <td>{{ $t("goodsDetail.smaplePrice") }}</td>
              <td style="font-size: 18px;">￥{{ priceRes.priceTwo }}</td>
            </tr>

            <!-- 起订量 -->
            <tr>
              <td>{{ $t("mall.MinimumOrderQuantity") }}</td>
              <td>>{{ minimumQuantity }}{{ $t("mall.piece") }}</td>
            </tr>

            <!-- 交期天数 -->
            <tr>
              <td>{{ $t("goodsDetail.TransactionTime") }}</td>
              <td>{{ }}{{ $t("unit.day") }}</td>
            </tr>


            <!-- 订购方式 -->
            <tr>
              <td>{{ $t("goodsDetail.supplyModes") }}</td>
              <td>
                <el-select v-model="buyType" size="small" @change="handleBuyTypeChange">
                  <el-option :value="i-1" v-for="i in 2" :key="i"
                             :label="$t(`goodsDetail.smaplePriceValue[${i - 1}]`)"></el-option>
                </el-select>
              </td>
            </tr>

            <!-- 数量 -->
            <tr>
              <td>{{ $t("goodsDetail.num") }}</td>
              <td>
                <el-input-number v-model="gooodnum" :min="1" :step="step" @change="handleSizeChange" size="small"
                                 :disabled="!boxSize"/>
              </td>
            </tr>

            <!-- 包装大小 -->
            <tr>
              <td>{{ $t("goodsDetail.boxSize") }}</td>
              <td>
                <el-select v-model="boxSize" size="small" @change="handleBoxSizeChange">
                  <el-option :value="item.value" v-for="(item,i) in source.boxSize" :key="i"
                             :label="$t(item.label)"></el-option>
                </el-select>
              </td>
            </tr>

            <!-- 包装数量 -->
            <tr>
              <td>{{ $t("goodsDetail.packNum") }}</td>
              <td>
                {{ boxRes }}
              </td>
            </tr>
            <tr>
              <td>
                <el-button type="primary" @click="handleConfirm">{{ $t("operation.orderNow") }}</el-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="goods-params-list">
        <div>
          <span style="color: #ccc;">{{ $t("goodsDetail.goodsModel") }}</span>
          <span><i class="el-icon-truck"
                   style="color:red;"></i>{{ $t("goodsDetail.FreightInsurance") }}</span>
        </div>
        <div>
          <span style="color: #ccc;">{{ $t("goodsDetail.TransactionSupport") }}</span>
          <span>
						<li class="el-icon-umbrella" style="color:red;"></li>{{ $t("goodsDetail.InsurancePayment") }}
					</span>
        </div>
        <div>
          <span style="color: #ccc;">{{ $t("goodsDetail.Payment") }}</span>
          <span>{{ $t("goodsDetail.PaymentMethod") }}</span>
        </div>
      </div>

    </div>
  </div>
</template>


<script>
import {mapGetters,mapState} from "vuex";
import PriceCalculate from "@/views/Custom/components/calculate/price/price";
import BoxCalculate from "@/views/Custom/components/calculate/box/box";
import PreviewCalculate from "@/views/Custom/components/calculate/preview/preview";
import {getGoodsCustomMode} from "@/utils";

export default {
  name: 'goodsDetail',
  data() {
    return {
      loadingBox: false,
      type: this.$route.query.type,
      module: null,
      // 起订量
      minimumQuantity: '',
      // 计算的价格
      priceRes: {
        price: 0,
        volume: 0,
        weight: 0,
      },
      buyType: 0,
      boxSize: 'S',
      boxRes: 0,

      source: {
        buyType: [
          {value: 0, label: 'goodsDetail.smaplePriceValue[0]'},
          {value: 1, label: 'goodsDetail.smaplePriceValue[1]'}
        ],
        boxSize: [
          {value: 'S', label: 'goodsDetail.boxSizeValue[0]'},
          {value: 'L', label: 'goodsDetail.boxSizeValue[1]'}
        ],
      },
      priceCalcModel: null,
      boxCalcModel: null,

      value: '',
      gooodnum: "",

      previewUrl: '',
    }
  },
  computed: {
    ...mapGetters(["fileServer", "config", "customInfo"]),
	...mapState(["params"]),
	
	
    step() {
      if (this.buyType == 0) {
        return this.boxRes || 1;
      }
      return 1;
    },
  },
  watch: {
    boxRes(val) {
      this.handleSizeChange(this.gooodnum);
    }
  },
  async mounted() {
    // if (!this.customInfo.confirm) {
    //   this.$message.warning("设计数据不存在，请重新设计。")
    //   this.$router.back();
    //   return;
    // }
    this.module = getGoodsCustomMode(this.type)
	
	
	
	

    let {goodsMpfUserMinimumQuantity, goodsMstUserMinimumQuantity} = this.config;
    this.minimumQuantity = this.type == 0 ? goodsMpfUserMinimumQuantity : goodsMstUserMinimumQuantity
    this.gooodnum = this.minimumQuantity;

    // 计算价格组装数据
    let data = PriceCalculate.from(this.params).get();
    this.priceCalcModel = data;


    // 获取预览设计图的地址
    let preview = PreviewCalculate.from(data, this.params).get();
	
    preview._scale = 1
    this.previewUrl = `https://3d.hxcoolingmall.com/u${this.module}.html#${JSON.stringify(preview)}`

    // 计算价格
    let url = this.type == 0 ? "calcMpfUserPrice" : "calcMstUserPrice"
    let priceRes = await this.$req.post(`/client/goods/calc/${url}`, data);
    priceRes.priceTwo = this.$bigDecimal.multiply(priceRes.price, 2);
    this.priceRes = priceRes

    this.calcBox();
  },
  methods: {
	  gopreviewUrl(url){
		  console.log(url)
		window.open(url)  
	  },
	  
    handleBuyTypeChange(val) {
      console.log(val)
      this.handleSizeChange(this.gooodnum);
    },
    handleSizeChange(val) {
      if (this.buyType == 0) {
        let n = this.boxRes;
        if (val % n != 0) {
          this.$nextTick(_ => {
            this.gooodnum = Math.ceil(val / n) * n
          })
        }
      }
    },
    handleBoxSizeChange(val) {
      this.calcBox();
    },
    calcBox() {
      let data = this.priceCalcModel;
	  console.log(this.params,"this.params")
      let boxModel = BoxCalculate.from(data)
          .setBoxSize(this.boxSize)
          .setPipeDirc(this.params.buttTube.location.otherObj?this.params.buttTube.location.otherObj.VP.value:'')
          .setMotnL( this.params.page3.bracket?this.params.page3.bracket.otherObj.length.value:'')
          .get();
      this.boxCalcModel = boxModel;
      this.loadingBox = true;
      let url = this.type == 0 ? "calcMpfUserBox" : "calcMstUserBox";
	  
      this.$req.post("/client/goods/calc/" + url, boxModel).then(res => {
		  
        this.boxRes = res.N;
      }).finally(() => this.loadingBox = false)
    },
    handleConfirm() {
      let buyModel = {
        size: this.gooodnum,
        price: this.priceRes,
        box: this.boxRes,
        boxSize: this.boxSize,
        type: this.buyType,
        priceCalc:this.priceCalcModel
      }
      this.$store.commit("SET_CUSTOM_INFO", {buyModel,boxCalcModel:this.boxCalcModel})

      this.$router.push({path: '/custom/order?type=' + this.type})
    },
  }
};
</script>

<style scoped>
.wrap {
  width: 1000px;
  margin: 40px auto;
  margin-top: 60px;
  color: #555;
  font-size: 14px;
}

.nav-top {
  margin-top: 60px;
  font-size: 18px;
}

.goods-title {
  padding: 20px 0;
}

.goods-content {
  margin-left: 40px;
  width: 700px;
}

.goods-params {
  display: flex;
  flex-direction: row;
}

.goods-imgs {
  height: 100%;
  position: relative;
}

.goods-imgs iframe {
  height: 100%;
  border: none;
}

.goods-params-tab {

  width: 100%;
}

.goods-params-tab tr td {
  padding: 15px 0px;
  text-align: left;
}

.goods-params-list {
  display: flex;
  flex-direction: row;
  padding: 10px;
}

.goods-params-list div {
  margin-right: 40px;
}

.goods-params-list div span {
  margin-right: 20px;
}

.goods-info-title {
  background-color: #eee;
  color: #ccc;
  padding: 10px;
  margin-top: 20px;
}

.goods-info-tab {
  width: 100%;
  border: 1px solid #ccc;
}

.goods-info-tab tr td {

  padding: 10px 0px;
  text-align: center;
}

.goods-detail {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.block {
  padding: 4px 8px;
  text-align: center;
  margin-left: 10px;
  font-size: 12px;
  border: 1px solid #CCCCCC;

}

.change {
  border: 1px solid #e70000;
  cursor: pointer;
}
</style>
